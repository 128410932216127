// //production
// export const firebaseConfig = {
//   apiKey: 'AIzaSyDorczJDdNnl96u6B9S8e_BAaeTkrKCFpw',
//   authDomain: 'ecoris-map.firebaseapp.com',
//   projectId: 'ecoris-map',
//   storageBucket: 'ecoris-map.appspot.com',
//   messagingSenderId: '46423401833',
//   appId: '1:46423401833:web:5486b709141438416309f8',
//   measurementId: 'G-R3G2RJ7N8P',
// };

//export const maptilerKey = 'DDCRft2B0fgM7KhEBOHA';
//export const reCaptureSiteKey = '6Lf3D_4hAAAAAMb8Bumvfn5uylU3cPvjf7HX640X';
//development
export const firebaseConfig = {
  apiKey: 'AIzaSyAktROemZMYW7JLpn6hITbtQiaZwRHcouQ',
  authDomain: 'ecorismap.firebaseapp.com',
  projectId: 'ecorismap',
  storageBucket: 'ecorismap.appspot.com',
  messagingSenderId: '498883984098',
  appId: '1:498883984098:web:8bbc9b6ec59f4cf130bcbb',
  measurementId: 'G-HN0PQ70JBY',
};
export const reCaptureSiteKey = '6LdShQUgAAAAAJkKZojWRsgIRo1leXMP9QA3YYVZ';
export const maptilerKey = '4C9yj47cvmSSmLHwYdfO';
